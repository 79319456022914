/**
 * @generated SignedSource<<01201d1ab974b586ec680768c8eb6242>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteServiceReceiptItemInput = {
  serviceReceiptItemId: string;
  serviceReceiptWebToken: string;
};
export type DeleteServiceReceiptItemMutation$variables = {
  input: DeleteServiceReceiptItemInput;
};
export type DeleteServiceReceiptItemMutation$data = {
  readonly deleteServiceReceiptItem: {
    readonly deletedServiceReceiptItemId: string | null | undefined;
  } | null | undefined;
};
export type DeleteServiceReceiptItemMutation = {
  response: DeleteServiceReceiptItemMutation$data;
  variables: DeleteServiceReceiptItemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteServiceReceiptItemPayload",
    "kind": "LinkedField",
    "name": "deleteServiceReceiptItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedServiceReceiptItemId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteServiceReceiptItemMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteServiceReceiptItemMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "daee147ab7dc003f0f71b2523006c20a",
    "id": null,
    "metadata": {},
    "name": "DeleteServiceReceiptItemMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteServiceReceiptItemMutation(\n  $input: DeleteServiceReceiptItemInput!\n) {\n  deleteServiceReceiptItem(input: $input) {\n    deletedServiceReceiptItemId\n  }\n}\n"
  }
};
})();

(node as any).hash = "a3c8982fd63184bc35a582cc9d487293";

export default node;
