import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, message, Modal, Table, Tag } from "antd";
import accounting from "accounting";
import CompleteItemFormModal from "../CompleteItemFormModal";
import DeleteServiceReceiptItemMutation from "../../../mutations/DeleteServiceReceiptItemMutation";
import {DeleteServiceReceiptItemInput} from "../../../mutations/__generated__/DeleteServiceReceiptItemMutation.graphql";
import styles from './index.module.less';
import {getExecutionString} from "../../../lib/modelHelpers/serviceReceiptItem";
import {ServiceReceiptShow_serviceReceipt$data} from "../__generated__/ServiceReceiptShow_serviceReceipt.graphql";

const {confirm} = Modal;

export interface ServiceReceiptItemTableProps {
    serviceReceipt: ServiceReceiptShow_serviceReceipt$data;
    onChange?: () => void;
}

export default React.memo(function ServiceReceiptItemTable(props: ServiceReceiptItemTableProps) {
    const [visibleCompleteItemFormModal, setVisibleCompleteItemFormModal] = React.useState<boolean>(false);
    const [selectedServiceReceiptItem, setSelectedServiceReceiptItem] = React.useState<any | null>(null);

    const fullSizeColumns = [
        {
            title: 'Titel',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Objekt',
            dataIndex: 'customerObject',
            key: 'customerObject',
        },
        {
            title: 'Menge',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Ausführung',
            dataIndex: 'execution',
            key: 'execution',
        },
    ];
    if (props.serviceReceipt.status === "DRAFT") {
        fullSizeColumns.push({
            title: '',
            dataIndex: 'actions',
            key: 'actions',
        });
    }

    let fullSizeDataSource: any[] = [];
    let mobileDataSource: any[] = [];
    if (props.serviceReceipt.items.edges) {
        for (const itemEdge of props.serviceReceipt.items.edges) {
            const item = itemEdge!.node;
            fullSizeDataSource.push({
                key: item.id,
                title: <div style={{whiteSpace: 'pre-wrap'}}>
                    <strong>{item.title}</strong><br/>
                    {item.description}
                </div>,
                customerObject: item.customerObject ? <>
                    {item.customerObject.name ? <>{item.customerObject.name}<br/></> : ""}
                    {item.customerObject.streetAddress ? <>{item.customerObject.streetAddress}<br/></> : ""}
                    {item.customerObject.zip ? item.customerObject.zip + " " : ""}
                    {item.customerObject.city}
                </> : "---",
                quantity: (item.quantity == null) ? <Tag
                    color={'orange'}>Ausstehend</Tag> : (accounting.formatMoney(item.quantity, "", 2, ".", ",") + (item.unit ? (" " + item.unit) : '')),
                execution: getExecutionString(item),
                actions: props.serviceReceipt.status === "DRAFT" &&
                    <div className={styles.hideOnPrint}>{item.status === "COMPLETED" &&
                        <Tag color={"green"}
                             title={"Position wurde abgeschlossen"}><CheckOutlined/> Abgeschlossen</Tag>}{item.status === "OPEN" &&
                        <Button title={"Position abschließen"} type={"primary"} onClick={() => {
                            setSelectedServiceReceiptItem(item);
                            setVisibleCompleteItemFormModal(true);
                        }}>Abschließen</Button>}<Button style={{marginLeft: 8, marginTop: 8}}
                                                        title={"Position von Leistungsbeleg löschen"}
                                                        onClick={() => deleteServiceReceiptItem(item.id)}>Löschen</Button>
                    </div>
            });
        }
        for (const itemEdge of props.serviceReceipt.items.edges) {
            const item = itemEdge!.node;
            mobileDataSource.push({
                key: item.id,
                item: <>
                    <div style={{whiteSpace: 'pre-wrap'}}>
                        <strong>{item.title}</strong><br/>
                        {item.description}
                    </div>
                    <br/>
                    {item.customerObject && <>
                        {item.customerObject.name ? <>{item.customerObject.name}<br/></> : ""}
                        {item.customerObject.streetAddress ? <>{item.customerObject.streetAddress}<br/></> : ""}
                        {item.customerObject.zip ? item.customerObject.zip + " " : ""}
                        {item.customerObject.city}
                        <br/><br/></>}
                    Menge: {item.quantity == null ? <Tag color={'orange'}>Ausstehend</Tag> : (accounting.formatMoney(item.quantity, "", 2, ".", ",") + (item.unit ? (" " + item.unit) : ''))}<br/>
                    {item.executionStartDate && <>Ausführung: {getExecutionString(item)}</>}
                    {props.serviceReceipt.status === "DRAFT" &&
                        <div className={styles.hideOnPrint} style={{marginTop: 8}}>
                            {item.status === "COMPLETED" &&
                                <Tag color={"green"} title={"Position wurde abgeschlossen"}><LegacyIcon
                                    type={"check"}/> Abgeschlossen</Tag>}
                            {item.status === "OPEN" &&
                                <Button title={"Position abschließen"} type={"primary"} onClick={() => {
                                    setSelectedServiceReceiptItem(item);
                                    setVisibleCompleteItemFormModal(true);
                                }}>Abschließen</Button>}
                            <Button style={{marginLeft: 8, marginTop: 8}} title={"Position von Leistungsbeleg löschen"}
                                    onClick={() => deleteServiceReceiptItem(item.id)}>Löschen</Button>
                        </div>}
                </>,
            });
        }
    }

    const deleteServiceReceiptItem = (serviceReceiptItemId: string) => {
        confirm({
            title: "Position wirklich löschen?",
            okText: "Löschen",
            cancelText: "Abbrechen",
            onOk() {
                let input: DeleteServiceReceiptItemInput = {
                    serviceReceiptWebToken: props.serviceReceipt.webToken,
                    serviceReceiptItemId: serviceReceiptItemId,
                }
                DeleteServiceReceiptItemMutation(input, (isSuccess) => {
                    if (isSuccess) {
                        if (props.onChange) {
                            props.onChange();
                        }
                        message.success('Position erfolgreich gelöscht');
                    } else {
                        message.error('Fehler beim Löschen der Position');
                    }
                });
            },
        });
    };

    return (
        <>
            <Table
                className={styles.fullSizeTable}
                dataSource={fullSizeDataSource}
                columns={fullSizeColumns}
                pagination={false}
            />
            <Table
                className={styles.mobileTable}
                dataSource={mobileDataSource}
                columns={[
                    {
                        title: 'Position',
                        dataIndex: 'item',
                        key: 'item',
                    },
                ]}
                pagination={false}
                />
            {selectedServiceReceiptItem && <CompleteItemFormModal
                key={selectedServiceReceiptItem.id}
                serviceReceiptWebToken={props.serviceReceipt.webToken}
                serviceReceiptItem={selectedServiceReceiptItem}
                visible={visibleCompleteItemFormModal}
                onCancel={() => setVisibleCompleteItemFormModal(false)}
                onChange={() => {
                    if (props.onChange) {
                        props.onChange();
                    }
                    setVisibleCompleteItemFormModal(false);
                }}
            />}
        </>
    );
}, function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});