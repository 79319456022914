import React, {useEffect, useRef} from 'react';
import SignaturePad from "signature_pad";
import {Button} from "antd";

export interface SignatureInputPros {
    onSave?: (base64File: string) => void,
    value?: string|null,
    onChange?: (value: string|null) => void,
}

export default React.memo(React.forwardRef((props: SignatureInputPros, ref: React.Ref<HTMLCanvasElement>) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const signaturePadRef = useRef<SignaturePad>();

    useEffect(() => {
        if (canvasRef.current && !signaturePadRef.current) {
            signaturePadRef.current = new SignaturePad(canvasRef.current);
            signaturePadRef.current.onEnd = (event) => {
              if (props.onChange) {
                  let base64File = signaturePadRef.current?.toDataURL();
                  if (typeof base64File === "undefined") {
                      props.onChange(null)
                  } else {
                      props.onChange(base64File)
                  }
              }
            };
        }
    }, [props]);

    const clearSignature = () => {
        if (signaturePadRef.current) {
            signaturePadRef.current.clear();
        }
    }

    return (
        <div>
            <canvas style={{border: "2px solid red"}} ref={canvasRef} id="signature-pad" width={400} height={200}></canvas>
            <div>
                <Button style={{marginRight: 8}} onClick={clearSignature}>Leeren</Button>
            </div>
        </div>
    )
}), function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});