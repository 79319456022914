import React, {useEffect} from 'react';
import graphql from 'babel-plugin-relay/macro';
import {createRefetchContainer} from "react-relay";
import {withRouter} from "react-router-dom";
import dayjs from "dayjs";
import {Row, Col, Typography} from 'antd';
import ServiceReceiptItemTable from "../ServiceReceipt/ItemTable";
import CountryDisplay from "../UI/DataDisplay/CountryDisplay";
import ServiceReceiptSignForm from "./SignForm";
import Customer from "../../models/Customer";
import {ServiceReceiptShow_serviceReceipt$data} from "./__generated__/ServiceReceiptShow_serviceReceipt.graphql";
import styles from './index.module.less';

const {Title} = Typography;

export interface ServiceReceiptShowProps {
    serviceReceipt: ServiceReceiptShow_serviceReceipt$data,
    history?: any,
    relay?: any
}

function ServiceReceiptShow(props: ServiceReceiptShowProps) {

    useEffect(() => {
        document.title = "Leistungsbeleg " + props.serviceReceipt.number;
    }, [props.serviceReceipt]);

    return (
        <div>
            <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={18} xxl={12}>
                        {props.serviceReceipt.company.logoUrl &&
                        <img src={props.serviceReceipt.company.logoUrl as string} alt="" style={{
                            maxWidth: 250, maxHeight: 200, marginBottom: 24, float: "right"
                        }} />}
                        <div style={{clear: "both"}} />
                        <Title level={2} style={{marginBottom: 32}}>Leistungsbeleg Nr. {props.serviceReceipt.number}</Title>
                        <>
                            <div>
                                <p><strong>Auftraggeber</strong></p>
                                {props.serviceReceipt.customer.companyName &&
                                <>{props.serviceReceipt.customer.companyName}<br/></>}
                                {(props.serviceReceipt.customer.firstName || props.serviceReceipt.customer.lastName) &&
                                <>{props.serviceReceipt.customer.firstName ? props.serviceReceipt.customer.firstName + " " : ""}{props.serviceReceipt.customer.lastName}<br/></>}
                                {props.serviceReceipt.customer.streetAddress &&
                                <>{props.serviceReceipt.customer.streetAddress}<br/></>}
                                {props.serviceReceipt.customer.zip &&
                                <>{props.serviceReceipt.customer.zip}</>}
                                {props.serviceReceipt.customer.city &&
                                <> {props.serviceReceipt.customer.city}</>}
                                {props.serviceReceipt.customer.country &&
                                <><br/><CountryDisplay
                                    value={props.serviceReceipt.customer.country as string | null}/></>}
                            </div>
                            {(props.serviceReceipt.company) &&
                            <div style={{marginTop: 16}}>
                                <p><strong>Auftragnehmer</strong></p>
                                {props.serviceReceipt.company.companyName}<br/>
                                {props.serviceReceipt.company.streetAddress}<br/>
                                {props.serviceReceipt.company.zip} {props.serviceReceipt.company.city}
                            </div>}
                        </>

                        <Title style={{marginTop: 32}} level={4}>Leistungen</Title>
                        <div
                            style={{marginTop: 16, marginBottom: 16}}
                        >
                            {(!props.serviceReceipt?.items.edges || props.serviceReceipt?.items.edges.length === 0) &&
                                <p>Keine Leistungen vorhanden</p>}
                            {props.serviceReceipt?.items.edges && props.serviceReceipt?.items.edges.length > 0 &&
                            <ServiceReceiptItemTable
                                serviceReceipt={props.serviceReceipt}
                                onChange={() => {
                                    if (props.relay?.refetch) {
                                        props.relay?.refetch((prev) => ({id: props.serviceReceipt.id}));
                                    }
                                }}
                            />}
                        </div>
                        {props.serviceReceipt.signedAt && props.serviceReceipt.notes &&
                        <>
                            <p><strong>Anmerkungen</strong></p>
                            <p style={{whiteSpace: 'pre-wrap'}}>{props.serviceReceipt.notes}</p>
                        </>}
                        {(props.serviceReceipt.status !== "DRAFT" && props.serviceReceipt.status !== "COMPLETED") && <p style={{marginTop: 64}}>Der Auftraggeber{new Customer(props.serviceReceipt.customer).getName() !== '---' ? (' (' + new Customer(props.serviceReceipt.customer).getName() + ')') : ''} bestätigt durch seine nachstehende Unterschrift, die Ausführung der angegebenen Leistungen.</p>}
                        {props.serviceReceipt.status === "DRAFT" &&
                            <>
                                <ServiceReceiptSignForm serviceReceipt={props.serviceReceipt} onChange={() => {
                                    if (props.relay?.refetch) {
                                        props.relay?.refetch((prev) => ({id: props.serviceReceipt.id}));
                                    }
                                }
                                } />
                            </>}
                        {props.serviceReceipt.signedAt && props.serviceReceipt.signatureImageUrl &&
                            <>
                                <p>{props.serviceReceipt.signatureCity},
                                    den {props.serviceReceipt.signatureDate ? dayjs(props.serviceReceipt.signatureDate as string, "YYYY-MM-DD").format("DD.MM.YYYY") : ""}</p>
                                <img src={props.serviceReceipt.signatureImageUrl as string} alt=""/>
                                {props.serviceReceipt.signatoryName && <p>{props.serviceReceipt.signatoryName}</p>}
                            </>}
                        {props.serviceReceipt.completedAt &&
                            <div className={styles.hideOnPrint} style={{height: 150}}/>}
                    </Col>
            </Row>
        </div>
    );
}

// @ts-ignore
export default createRefetchContainer<ServiceReceiptShowProps>(withRouter(ServiceReceiptShow), {
    serviceReceipt: graphql`
    fragment ServiceReceiptShow_serviceReceipt on ServiceReceipt {
        id
        number
        webToken
        status
        notes
        signatoryName
        signatureCity
        signatureDate
        signatureImageUrl
        completedAt
        signedAt
        company {
            companyName
            streetAddress
            zip
            city
            logoUrl
        }
        customer {
            type
            salutation
            firstName
            lastName
            companyName
            streetAddress
            zip
            city
            country
        }
        customerContactPerson {
          salutation
          firstName
          lastName
        }
        items(first: 150, orderBy: $itemsOrderBy) {
          edges {
            node {
              id
              title
              description
              unit
              quantity
              quantityInputRequired
              hideDuration
              status
              executionStartDate
              executionStartTime
              executionEndDate
              executionEndTime
              customerObject {
                name
                streetAddress
                zip
                city
              }
            }
          }
        }
    }
  `,
}, graphql`
    query ServiceReceiptShowRefetchQuery(
        $webToken: String!
        $itemsOrderBy: ServiceReceiptItemOrder!
    ) {
        serviceReceipt(webToken: $webToken) {
            ...ServiceReceiptShow_serviceReceipt
        }
    }
  `,);