import React from 'react';
import {withRouter} from "react-router-dom";
import {Layout} from 'antd';
import moment from 'moment';
import 'moment/locale/de';
import styles from './index.module.less';

const {Content, Footer} = Layout;

function MainLayout(props) {
    const Component = props.component;
    const route = props.route;

    moment.locale('de');

    return (
        <Layout>
            <Content className={styles.content}>
                <Layout className={styles.layoutContainer}>
                    <Content style={{ padding: '0 24px', minHeight: 280 }}>
                        <Component route={route} />
                    </Content>
                </Layout>
            </Content>
            <Footer className={styles.footer}>Erstellt mit <a href="https://www.mendato.com" target="_blank" rel="noopener noreferrer">Mendato</a></Footer>
        </Layout>
    );
}

export default withRouter(MainLayout)