import React from 'react';
import {withRouter} from "react-router-dom";
import graphql from 'babel-plugin-relay/macro';
import { QueryRenderer } from "react-relay";
import environment from "../../Environment";
import ContentWrapper from "../../components/UI/Layout/ContentWrapper";
import LoadingSpin from "../../components/UI/Feedback/LoadingSpin";
import {Alert} from "antd";
import ServiceReceiptShow from "../../components/ServiceReceipt/ServiceReceiptShow";
import {
    ServiceReceiptPageQuery
} from "./__generated__/ServiceReceiptPageQuery.graphql";

const query = graphql`            
    query ServiceReceiptPageQuery (
        $webToken: String!
        $itemsOrderBy: ServiceReceiptItemOrder!
    ) {
        serviceReceipt(webToken: $webToken) {
            ...ServiceReceiptShow_serviceReceipt
        }
    }`;

function ServiceReceiptPage(props) {
    let pathNames = window.location.pathname.split('/');
    let webToken: string|null = null;
    if (pathNames.length > 2 && pathNames[2] !== "")  {
        webToken = pathNames[2];
    }

    if (!webToken) {
        return <Alert message={"Leistungsbeleg konnte nicht gefunden werden"} type={"error"} />
    } else {
        return (
            <QueryRenderer<ServiceReceiptPageQuery>
                environment={environment}
                query={query}
                variables={{
                    webToken: webToken,
                    itemsOrderBy: {
                        field: "EXECUTION_START",
                        direction: "ASC",
                    }
                }}
                render={({error, props}) => {
                    if (error) {
                        return <p>{error.message}</p>;
                    } else if (props) {
                        if (!props.serviceReceipt) {
                            return <Alert message={"Leistungsbeleg konnte nicht gefunden werden"} type={"error"} />;
                        } else {
                            return <ServiceReceiptShow serviceReceipt={props.serviceReceipt} />
                        }
                    }
                    return <ContentWrapper>
                        <LoadingSpin />
                    </ContentWrapper>;
                }}
            />
        );
    }
}

export default withRouter(ServiceReceiptPage);