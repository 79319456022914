import React from 'react';
import {Modal, Button, message, DatePicker, TimePicker, Form} from 'antd';
import FloatInput from "../../UI/Control/FloatInput";
import dayjs from "dayjs";
import CompleteServiceReceiptItemMutation from "../../../mutations/CompleteServiceReceiptItemMutation";

export interface CompleteItemFormModalProps {
    serviceReceiptWebToken: string;
    serviceReceiptItem: {
        id: string,
        quantity?: number | null,
        quantityInputRequired?: boolean | null,
        unit?: string | null,
        hideDuration?: boolean | null,
        executionStartDate?: string | null,
        executionEndDate?: string | null,
        executionStartTime?: string | null,
        executionEndTime?: string | null,
    };
    visible: boolean;
    onCancel?: () => void;
    onChange?: () => void;
}

function CompleteItemFormModal(props: CompleteItemFormModalProps) {
    const {serviceReceiptItem} = props;
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [executionStartDate, setExecutionStartDate] = React.useState<dayjs.Dayjs | null>(serviceReceiptItem.executionStartDate ? dayjs(serviceReceiptItem.executionStartDate, "YYYY-MM-DD") : dayjs());

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            if (dayjs(values.executionEndDate).isBefore(dayjs(values.executionStartDate), 'days')) {
                message.error('Enddatum darf nicht vor dem Startdatum sein');
                return;
            } else if (values.executionStartTime && values.executionEndTime &&
                dayjs(values.executionStartDate).isSame(dayjs(values.executionEndDate), 'days') &&
                dayjs(values.executionEndTime).isBefore(dayjs(values.executionStartTime), 'minutes')
            ) {
                message.error('Endzeit darf nicht vor der Startzeit sein');
                return;
            }

            setSubmitting(true);
            let input = values;
            input.serviceReceiptWebToken = props.serviceReceiptWebToken;
            input.serviceReceiptItemId = serviceReceiptItem.id;
            input.executionStartDate = (input.executionStartDate as dayjs.Dayjs).format("YYYY-MM-DD");
            if (input.executionStartTime) {
                input.executionStartTime = (input.executionStartTime as dayjs.Dayjs).format('HH:mm');
            }
            input.executionEndDate = (input.executionEndDate as dayjs.Dayjs).format("YYYY-MM-DD");
            if (input.executionEndTime) {
                input.executionEndTime = (input.executionEndTime as dayjs.Dayjs).format('HH:mm');
            }

            CompleteServiceReceiptItemMutation(input, (response) => {
                if (response) {
                    form.resetFields();
                    if (props.onChange) {
                        props.onChange();
                    }
                    message.success("Position erfolgreich abgeschlossen");
                } else {
                    message.error("Fehler beim Abschließen der Position");
                }
                setSubmitting(false);
            });
        });
    };

    const handleCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    function disabledEndDate(date: dayjs.Dayjs) {
        if (!date) {
            return false;
        }

        if (executionStartDate && date) {
            if (date < executionStartDate.startOf('day')) {
                return true;
            }
            if (date.isAfter(dayjs(executionStartDate).add(1, 'days'), 'day')) {
                return true;
            }
        }

        return false;
    }

    const onChangeExecutionStartDate = (date: dayjs.Dayjs | null) => {
        setExecutionStartDate(date);
        form.setFieldsValue({executionEndDate: date});
    }

    return (
        (<Modal
            title={"Position abschließen"}
            open={props.visible}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Schließen
                </Button>,
                <Button key="save" type="primary" loading={submitting} onClick={() => {
                    handleSubmit()
                }}>Abschließen</Button>
            ]}
        >
            <Form form={form} layout={"vertical"}>
                {serviceReceiptItem.quantityInputRequired &&
                    <Form.Item label="Menge" name={'quantity'} initialValue={serviceReceiptItem.quantity}
                               rules={[{required: true, message: 'Bitte gib die Menge an'}]}>
                        <FloatInput style={{maxWidth: 150}}
                                    addonAfter={serviceReceiptItem.unit ? serviceReceiptItem.unit : undefined}/>
                    </Form.Item>}
                <Form.Item label="Start der Ausführung" required={true}>
                    <Form.Item name={'executionStartDate'} initialValue={executionStartDate}
                               rules={[{type: 'object', required: true, message: 'Bitte gib ein Startdatum an'}]}
                               style={{display: 'inline-block', marginBottom: 0, paddingBottom: 0}}>
                        <DatePicker
                            format="DD.MM.YYYY"
                            placeholder={"Datum auswählen"}
                            allowClear={false}
                            onChange={onChangeExecutionStartDate}
                        />
                    </Form.Item>
                    {!serviceReceiptItem.hideDuration && <Form.Item name={'executionStartTime'}
                                                                    initialValue={serviceReceiptItem.executionStartTime ? dayjs(serviceReceiptItem.executionStartTime, "HH:mm:ss") : null}
                                                                    style={{
                                                                        display: 'inline-block',
                                                                        marginBottom: 0,
                                                                        paddingBottom: 0
                                                                    }}>
                        <TimePicker style={{marginLeft: 8}} format={"HH:mm"} placeholder={"Uhrzeit"}
                                    allowClear={true} needConfirm={false}/>
                    </Form.Item>}
                </Form.Item>
                <Form.Item label="Ende der Ausführung" required={true}>
                    <Form.Item name={'executionEndDate'}
                               initialValue={serviceReceiptItem.executionEndDate ? dayjs(serviceReceiptItem.executionEndDate, "YYYY-MM-DD") : dayjs()}
                               rules={[{type: 'object', required: true, message: 'Bitte gib ein Enddatum an'}]}
                               style={{display: 'inline-block', marginBottom: 0, paddingBottom: 0}}>
                        <DatePicker
                            format="DD.MM.YYYY"
                            placeholder={"Datum auswählen"}
                            allowClear={false}
                            disabledDate={disabledEndDate}
                        />
                    </Form.Item>
                    {!serviceReceiptItem.hideDuration && <Form.Item name={'executionEndTime'}
                                                                    initialValue={serviceReceiptItem.executionEndTime ? dayjs(serviceReceiptItem.executionEndTime, "HH:mm:ss") : null}
                                                                    style={{
                                                                        display: 'inline-block',
                                                                        marginBottom: 0,
                                                                        paddingBottom: 0
                                                                    }}>
                        <TimePicker style={{marginLeft: 8}} format={"HH:mm"} placeholder={"Uhrzeit"}
                                    allowClear={true} needConfirm={false}/>
                    </Form.Item>}
                </Form.Item>
            </Form>
        </Modal>)
    );
}

export default CompleteItemFormModal;