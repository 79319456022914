import React from 'react';

function ContentWrapper(props) {
    return (
        <div
            style={{
                margin: 16
            }}
        >
            {props.children}
        </div>
    )
}

export default ContentWrapper;