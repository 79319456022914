import React from "react";
import { Route, Switch } from "react-router-dom"
import MainLayout from "../layout/Main";
import ServiceReceiptPage from "./ServiceReceipt";

const routes = () =>
    <Switch>
        <Route
            path="/service-receipt"
            render={ (route) =>
                <MainLayout
                    component={ServiceReceiptPage}
                    route={route}
                />
            }
        />
    </Switch>;

export default routes;