export default class Customer {
    id: string;
    type: string;
    companyName: string|null;
    salutation: string|null;
    firstName: string|null;
    lastName: string|null;
    streetAddress: string|null;
    zip: string|null;
    city: string|null;
    email: string|null;
    phone: string|null;
    fax: string|null;
    website: string|null;
    constructor(customer) {
        this.id = (customer === undefined || customer.id === undefined) ? null : customer.id;
        this.type = (customer === undefined || customer.type === undefined) ? null : customer.type;
        this.companyName = (customer === undefined || customer.companyName === undefined) ? null : customer.companyName;
        this.salutation = (customer === undefined || customer.salutation === undefined) ? null : customer.salutation;
        this.firstName = (customer === undefined || customer.firstName === undefined) ? null : customer.firstName;
        this.lastName = (customer === undefined || customer.lastName === undefined) ? null : customer.lastName;
        this.streetAddress = (customer === undefined || customer.streetAddress === undefined) ? null : customer.streetAddress;
        this.zip = (customer === undefined || customer.zip === undefined) ? null : customer.zip;
        this.city = (customer === undefined || customer.city === undefined) ? null : customer.city;
        this.email = (customer === undefined || customer.email === undefined) ? null : customer.email;
        this.phone = (customer === undefined || customer.phone === undefined) ? null : customer.phone;
        this.fax = (customer === undefined || customer.fax === undefined) ? null : customer.fax;
        this.website = (customer === undefined || customer.website === undefined) ? null : customer.website;
    }
    getName(): string {
        if (this.type === 'PERSON') {
            if (this.firstName && this.lastName) {
                return this.firstName + ' ' + this.lastName;
            } else if (this.firstName) {
                return this.firstName;
            } else if (this.lastName) {
                return this.lastName;
            } else {
                // Fallback
                return '---';
            }
        } else {
            if (this.companyName) {
                return this.companyName;
            } else {
                // Fallback
                return '---';
            }
        }
    }
};