/**
 * @generated SignedSource<<ec3209b2ab30ea69b9a041631ccc1bb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CustomerType = "COMPANY" | "PERSON" | "%future added value";
export type ServiceReceiptItemStatus = "COMPLETED" | "OPEN" | "%future added value";
export type ServiceReceiptStatus = "COMPLETED" | "DRAFT" | "REVIEWED" | "SIGNED" | "WAITING_FOR_REVIEW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ServiceReceiptShow_serviceReceipt$data = {
  readonly company: {
    readonly city: string | null | undefined;
    readonly companyName: string | null | undefined;
    readonly logoUrl: any | null | undefined;
    readonly streetAddress: string | null | undefined;
    readonly zip: string | null | undefined;
  };
  readonly completedAt: any | null | undefined;
  readonly customer: {
    readonly city: string | null | undefined;
    readonly companyName: string | null | undefined;
    readonly country: any | null | undefined;
    readonly firstName: string | null | undefined;
    readonly lastName: string | null | undefined;
    readonly salutation: string | null | undefined;
    readonly streetAddress: string | null | undefined;
    readonly type: CustomerType;
    readonly zip: string | null | undefined;
  };
  readonly customerContactPerson: {
    readonly firstName: string | null | undefined;
    readonly lastName: string;
    readonly salutation: string;
  } | null | undefined;
  readonly id: string;
  readonly items: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly customerObject: {
          readonly city: string | null | undefined;
          readonly name: string | null | undefined;
          readonly streetAddress: string | null | undefined;
          readonly zip: string | null | undefined;
        } | null | undefined;
        readonly description: string | null | undefined;
        readonly executionEndDate: any | null | undefined;
        readonly executionEndTime: any | null | undefined;
        readonly executionStartDate: any | null | undefined;
        readonly executionStartTime: any | null | undefined;
        readonly hideDuration: boolean;
        readonly id: string;
        readonly quantity: number | null | undefined;
        readonly quantityInputRequired: boolean;
        readonly status: ServiceReceiptItemStatus;
        readonly title: string;
        readonly unit: string | null | undefined;
      };
    } | null | undefined> | null | undefined;
  };
  readonly notes: string | null | undefined;
  readonly number: number;
  readonly signatoryName: string | null | undefined;
  readonly signatureCity: string | null | undefined;
  readonly signatureDate: any | null | undefined;
  readonly signatureImageUrl: any | null | undefined;
  readonly signedAt: any | null | undefined;
  readonly status: ServiceReceiptStatus;
  readonly webToken: string;
  readonly " $fragmentType": "ServiceReceiptShow_serviceReceipt";
};
export type ServiceReceiptShow_serviceReceipt$key = {
  readonly " $data"?: ServiceReceiptShow_serviceReceipt$data;
  readonly " $fragmentSpreads": FragmentRefs<"ServiceReceiptShow_serviceReceipt">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streetAddress",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zip",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "salutation",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "itemsOrderBy"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ServiceReceiptShow_serviceReceipt",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "webToken",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signatoryName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signatureCity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signatureDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signatureImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logoUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerContactPerson",
      "kind": "LinkedField",
      "name": "customerContactPerson",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 150
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "itemsOrderBy"
        }
      ],
      "concreteType": "ServiceReceiptItemConnection",
      "kind": "LinkedField",
      "name": "items",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ServiceReceiptItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ServiceReceiptItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unit",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantityInputRequired",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hideDuration",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "executionStartDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "executionStartTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "executionEndDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "executionEndTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CustomerObject",
                  "kind": "LinkedField",
                  "name": "customerObject",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ServiceReceipt",
  "abstractKey": null
};
})();

(node as any).hash = "0a87c49e796834258bade4a7a7368334";

export default node;
