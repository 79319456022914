import React from 'react';

export interface CountryDisplayProps {
    value: string | null;
}

export default React.memo(function CountryDisplay(props: CountryDisplayProps) {
    let countries = {
        "DE": "Deutschland",
        "AT": "Österreich",
        "CH": "Schweiz",
        "AF": "Afghanistan",
        "EG": "Ägypten",
        "AX": "Åland",
        "AL": "Albanien",
        "DZ": "Algerien",
        "AS": "Amerikanisch-Samoa",
        "VI": "Amerikanische Jungferninseln",
        "AD": "Andorra",
        "AO": "Angola",
        "AI": "Anguilla",
        "AQ": "Antarktis",
        "AG": "Antigua und Barbuda",
        "GQ": "Äquatorialguinea",
        "AR": "Argentinien",
        "AM": "Armenien",
        "AW": "Aruba",
        "AC": "Ascension",
        "AZ": "Aserbaidschan",
        "ET": "Äthiopien",
        "AU": "Australien",
        "BS": "Bahamas",
        "BH": "Bahrain",
        "BD": "Bangladesch",
        "BB": "Barbados",
        "BY": "Belarus (Weißrussland)",
        "BE": "Belgien",
        "BZ": "Belize",
        "BJ": "Benin",
        "BM": "Bermuda",
        "BT": "Bhutan",
        "BO": "Bolivien",
        "BA": "Bosnien und Herzegowina",
        "BW": "Botswana",
        "BV": "Bouvetinsel",
        "BR": "Brasilien",
        "VG": "Britische Jungferninseln",
        "IO": "Britisches Territorium im Indischen Ozean",
        "BN": "Brunei Darussalam",
        "BG": "Bulgarien",
        "BF": "Burkina Faso",
        "BI": "Burundi",
        "EA": "Ceuta, Melilla",
        "CL": "Chile",
        "CN": "Volksrepublik China",
        "CP": "Clipperton",
        "CK": "Cookinseln",
        "CR": "Costa Rica",
        "CI": "Côte d'Ivoire (Elfenbeinküste)",
        "DK": "Dänemark",
        "DG": "Diego Garcia",
        "DM": "Dominica",
        "DO": "Dominikanische Republik",
        "DJ": "Dschibuti",
        "EC": "Ecuador",
        "SV": "El Salvador",
        "ER": "Eritrea",
        "EE": "Estland",
        "FK": "Falklandinseln",
        "FO": "Färöer",
        "FJ": "Fidschi",
        "FI": "Finnland",
        "FR": "Frankreich",
        "GF": "Französisch-Guayana",
        "PF": "Französisch-Polynesien",
        "GA": "Gabun",
        "GM": "Gambia",
        "GE": "Georgien",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GD": "Grenada",
        "GR": "Griechenland",
        "GL": "Grönland",
        "GB": "Großbritannien",
        "GP": "Guadeloupe",
        "GU": "Guam",
        "GT": "Guatemala",
        "GG": "Guernsey (Kanalinsel)",
        "GN": "Guinea",
        "GW": "Guinea-Bissau",
        "GY": "Guyana",
        "HT": "Haiti",
        "HM": "Heard- und McDonald-Inseln",
        "HN": "Honduras",
        "HK": "Hongkong",
        "IN": "Indien",
        "ID": "Indonesien",
        "IM": "Insel Man",
        "IQ": "Irak",
        "IR": "Iran",
        "IE": "Irland",
        "IS": "Island",
        "IL": "Israel",
        "IT": "Italien",
        "JM": "Jamaika",
        "JP": "Japan",
        "YE": "Jemen",
        "JE": "Jersey (Kanalinsel)",
        "JO": "Jordanien",
        "KY": "Kaimaninseln",
        "KH": "Kambodscha",
        "CM": "Kamerun",
        "CA": "Kanada",
        "IC": "Kanarische Inseln",
        "CV": "Kap Verde",
        "KZ": "Kasachstan",
        "QA": "Katar",
        "KE": "Kenia",
        "KG": "Kirgisistan",
        "KI": "Kiribati",
        "CC": "Kokosinseln",
        "CO": "Kolumbien",
        "KM": "Komoren",
        "CD": "Demokratische Republik Kongo",
        "KP": "Demokratische Volksrepublik Korea (Nordkorea)",
        "KR": "Republik Korea (Südkorea)",
        "HR": "Kroatien",
        "CU": "Kuba",
        "KW": "Kuwait",
        "LA": "Laos",
        "LS": "Lesotho",
        "LV": "Lettland",
        "LB": "Libanon",
        "LR": "Liberia",
        "LY": "Libyen",
        "LI": "Liechtenstein",
        "LT": "Litauen",
        "LU": "Luxemburg",
        "MO": "Macao",
        "MG": "Madagaskar",
        "MW": "Malawi",
        "MY": "Malaysia",
        "MV": "Malediven",
        "ML": "Mali",
        "MT": "Malta",
        "MA": "Marokko",
        "MH": "Marshallinseln",
        "MQ": "Martinique",
        "MR": "Mauretanien",
        "MU": "Mauritius",
        "YT": "Mayotte",
        "MK": "Mazedonien",
        "MX": "Mexiko",
        "FM": "Mikronesien",
        "MD": "Moldawien (Republik Moldau)",
        "MC": "Monaco",
        "MN": "Mongolei",
        "ME": "Montenegro",
        "MS": "Montserrat",
        "MZ": "Mosambik",
        "MM": "Myanmar (Burma)",
        "NA": "Namibia",
        "NR": "Nauru",
        "NP": "Nepal",
        "NC": "Neukaledonien",
        "NZ": "Neuseeland",
        "NI": "Nicaragua",
        "NL": "Niederlande",
        "AN": "Niederländische Antillen",
        "NE": "Niger",
        "NG": "Nigeria",
        "NU": "Niue",
        "MP": "Nördliche Marianen",
        "NF": "Norfolkinsel",
        "NO": "Norwegen",
        "OM": "Oman",
        "XO": "Orbit",
        "TL": "Osttimor (Timor-Leste)",
        "PK": "Pakistan",
        "PS": "Palästinensische Autonomiegebiete",
        "PW": "Palau",
        "PA": "Panama",
        "PG": "Papua-Neuguinea",
        "PY": "Paraguay",
        "PE": "Peru",
        "PH": "Philippinen",
        "PN": "Pitcairninseln",
        "PL": "Polen",
        "PT": "Portugal",
        "PR": "Puerto Rico",
        "TW": "Republik China (Taiwan)",
        "CG": "Republik Kongo",
        "RE": "Réunion",
        "RW": "Ruanda",
        "RO": "Rumänien",
        "RU": "Russische Föderation",
        "BL": "Saint-Barthélemy",
        "MF": "Saint-Martin",
        "SB": "Salomonen",
        "ZM": "Sambia",
        "WS": "Samoa",
        "SM": "San Marino",
        "ST": "São Tomé und Príncipe",
        "SA": "Saudi-Arabien",
        "SE": "Schweden",
        "SN": "Senegal",
        "RS": "Serbien",
        "SC": "Seychellen",
        "SL": "Sierra Leone",
        "ZW": "Simbabwe",
        "SG": "Singapur",
        "SK": "Slowakei",
        "SI": "Slowenien",
        "SO": "Somalia",
        "ES": "Spanien",
        "LK": "Sri Lanka",
        "SH": "St. Helena",
        "KN": "St. Kitts und Nevis",
        "LC": "St. Lucia",
        "PM": "Saint-Pierre und Miquelon",
        "VC": "St. Vincent und die Grenadinen",
        "ZA": "Südafrika",
        "SD": "Sudan",
        "GS": "Südgeorgien und die Südlichen Sandwichinseln",
        "SR": "Suriname",
        "SJ": "Svalbard und Jan Mayen",
        "SZ": "Swasiland",
        "SY": "Syrien",
        "TJ": "Tadschikistan",
        "TZ": "Tansania",
        "TH": "Thailand",
        "TG": "Togo",
        "TK": "Tokelau",
        "TO": "Tonga",
        "TT": "Trinidad und Tobago",
        "TA": "Tristan da Cunha",
        "TD": "Tschad",
        "CZ": "Tschechische Republik",
        "TN": "Tunesien",
        "TR": "Türkei",
        "TM": "Turkmenistan",
        "TC": "Turks- und Caicosinseln",
        "TV": "Tuvalu",
        "UG": "Uganda",
        "UA": "Ukraine",
        "HU": "Ungarn",
        "UY": "Uruguay",
        "UZ": "Usbekistan",
        "VU": "Vanuatu",
        "VA": "Vatikanstadt",
        "VE": "Venezuela",
        "AE": "Vereinigte Arabische Emirate",
        "US": "Vereinigte Staaten von Amerika (USA)",
        "VN": "Vietnam",
        "WF": "Wallis und Futuna",
        "CX": "Weihnachtsinsel",
        "EH": "Westsahara",
        "CF": "Zentralafrikanische Republik",
        "CY": "Zypern",
    }

    return (
        <div>{props.value ? countries[props.value] : ""}</div>
    );
}, function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});