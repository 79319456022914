import React, { CSSProperties } from 'react';
import { InputNumber } from 'antd';

export interface FloatInputProps {
    style?: CSSProperties;
    value?: number | null;
    onChange?: (value: number | null) => void;
    disabled?: boolean;
    allowNegative?: boolean;
    addonAfter?: string;
}

const FloatInput = (props: FloatInputProps) => {
    const onChangeValue = (value: number | null) => {
        if (props.onChange) {
            props.onChange(value);
        }
    };

    return (
        <InputNumber
            style={props.style}
            value={props.value != null ? props.value : null}
            onChange={onChangeValue}
            disabled={props.disabled}
            addonAfter={props.addonAfter}
            decimalSeparator={','}
            precision={2}
            min={props.allowNegative != null ? Number.MIN_SAFE_INTEGER : 0}
        />
    );
};

export default FloatInput;
