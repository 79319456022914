import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

function LoadingSpin() {
    const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
    return (
        <div
            style={{
                textAlign: "center",
            }}>
            <Spin indicator={antIcon} />
        </div>
    )
}

export default LoadingSpin;