/**
 * @generated SignedSource<<351ff7c4b3d4e6462ffe3a295b1b4484>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SignServiceReceiptInput = {
  notes?: string | null | undefined;
  serviceReceiptWebToken: string;
  signatoryName?: string | null | undefined;
  signatureCity: string;
  signatureImageFile: any;
};
export type SignServiceReceiptMutation$variables = {
  input: SignServiceReceiptInput;
};
export type SignServiceReceiptMutation$data = {
  readonly signServiceReceipt: {
    readonly serviceReceipt: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type SignServiceReceiptMutation = {
  response: SignServiceReceiptMutation$data;
  variables: SignServiceReceiptMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SignServiceReceiptPayload",
    "kind": "LinkedField",
    "name": "signServiceReceipt",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceReceipt",
        "kind": "LinkedField",
        "name": "serviceReceipt",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SignServiceReceiptMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SignServiceReceiptMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7b3b925a4f627ee08c496c90d02418a1",
    "id": null,
    "metadata": {},
    "name": "SignServiceReceiptMutation",
    "operationKind": "mutation",
    "text": "mutation SignServiceReceiptMutation(\n  $input: SignServiceReceiptInput!\n) {\n  signServiceReceipt(input: $input) {\n    serviceReceipt {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f6eff1995db4b28a32bec22d92e2dfb4";

export default node;
