import {
    commitMutation
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'
import {
    DeleteServiceReceiptItemInput,
    DeleteServiceReceiptItemMutation
} from "./__generated__/DeleteServiceReceiptItemMutation.graphql";

const mutation = graphql`mutation DeleteServiceReceiptItemMutation($input: DeleteServiceReceiptItemInput!) {
    deleteServiceReceiptItem(input: $input) {
      deletedServiceReceiptItemId
    }
  }
`;

const commit = (deleteServiceReceiptItemInput: DeleteServiceReceiptItemInput, callback) => {
    const variables = {
        input: deleteServiceReceiptItemInput
    };

    commitMutation<DeleteServiceReceiptItemMutation>(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                const id = response.deleteServiceReceiptItem?.deletedServiceReceiptItemId;
                callback(id);
            },
            onError: err => console.error(err),
        },
    )
}

export default commit;