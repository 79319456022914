import React from 'react'
import Routes from "./pages/Routes";
import {ConfigProvider} from "antd";
import locale from "antd/es/locale/de_DE";
import dayjs from 'dayjs';
import 'dayjs/locale/de';

dayjs.locale('de');

const App: React.FC = () => {
    return (
        <ConfigProvider locale={locale}>
            <div className="App">
                <Routes/>
            </div>
        </ConfigProvider>
    );
};

export default App;
