/**
 * @generated SignedSource<<f78ef3fa9a24b0f5c960ad8d887958fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CompleteServiceReceiptItemInput = {
  executionEndDate: any;
  executionEndTime?: any | null | undefined;
  executionStartDate: any;
  executionStartTime?: any | null | undefined;
  quantity?: number | null | undefined;
  serviceReceiptItemId: string;
  serviceReceiptWebToken: string;
};
export type CompleteServiceReceiptItemMutation$variables = {
  input: CompleteServiceReceiptItemInput;
};
export type CompleteServiceReceiptItemMutation$data = {
  readonly completeServiceReceiptItem: {
    readonly serviceReceiptItem: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type CompleteServiceReceiptItemMutation = {
  response: CompleteServiceReceiptItemMutation$data;
  variables: CompleteServiceReceiptItemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CompleteServiceReceiptItemPayload",
    "kind": "LinkedField",
    "name": "completeServiceReceiptItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceReceiptItem",
        "kind": "LinkedField",
        "name": "serviceReceiptItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompleteServiceReceiptItemMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompleteServiceReceiptItemMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bd1914232ac169a6156ffc9170f986c8",
    "id": null,
    "metadata": {},
    "name": "CompleteServiceReceiptItemMutation",
    "operationKind": "mutation",
    "text": "mutation CompleteServiceReceiptItemMutation(\n  $input: CompleteServiceReceiptItemInput!\n) {\n  completeServiceReceiptItem(input: $input) {\n    serviceReceiptItem {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "65689e30d8c4715436d092d869d7d119";

export default node;
