import React, {useEffect} from 'react';
import { Form } from '@ant-design/compatible';
import { Alert, Button, Input, message } from 'antd';
import dayjs from "dayjs";
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import {SignServiceReceiptInput} from "../../mutations/__generated__/SignServiceReceiptMutation.graphql";
import SignServiceReceiptMutation from "../../mutations/SignServiceReceiptMutation";
import SignatureInput from "../UI/Control/SignatureInput";
import styles from './index.module.less';
import {ServiceReceiptShow_serviceReceipt$data} from "./__generated__/ServiceReceiptShow_serviceReceipt.graphql";

const {TextArea} = Input;

export interface ServiceReceiptSignFormProps extends FormComponentProps {
    serviceReceipt: ServiceReceiptShow_serviceReceipt$data,
    onCancel?: () => void;
    onChange?: () => void;
    onSign?: () => void;
}

function ServiceReceiptSignForm(props: ServiceReceiptSignFormProps) {
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [itemsNotCompleted, setItemsNotCompleted] = React.useState<boolean>();

    const {getFieldDecorator} = props.form;

    useEffect(() => {
        let foundOpenItem = false;
        if (props.serviceReceipt.items.edges) {
            for (const itemEdge of props.serviceReceipt.items.edges) {
                if (itemEdge?.node.status === "OPEN") {
                    foundOpenItem = true;
                }
            }
        }
        setItemsNotCompleted(foundOpenItem);
    }, [props.serviceReceipt])

    const handleSubmit = () => {
        props.form.validateFields((err, values) => {
            if (!err) {
                setSubmitting(true);

                let input: SignServiceReceiptInput = {
                    serviceReceiptWebToken: props.serviceReceipt.webToken,
                    notes: values.notes,
                    signatureCity: values.signatureCity,
                    signatureImageFile: values.signatureImageFile,
                    signatoryName: values.signatoryName,
                }
                SignServiceReceiptMutation(input, (id) => {
                    if (id) {
                        message.success("Leistungsbeleg erfolgreich unterschrieben");
                        if (props.onChange) {
                            props.onChange();
                        }
                    } else {
                        message.error("Fehler beim Unterschreiben des Leistungsbelegs");
                    }
                });

            }
        });
    };

    return (
        <div>
            <Form layout={"vertical"}>
                <Form.Item label="Anmerkungen" style={{marginBottom: 64}}>
                    {getFieldDecorator('notes')(<TextArea rows={4}/>)}
                </Form.Item>
                {itemsNotCompleted && <Alert className={styles.hideOnPrint} type={"info"} showIcon={true} message={"Alle Leistungen müssen abgeschlossen sein, damit der Leistungsbeleg unterschrieben werden kann."} />}
                {!itemsNotCompleted && <>
                    <p>Der Auftraggeber bestätigt durch die nachstehende Unterschrift, die
                        Ausführung der angegeben Leistungen.</p>
                    <Form.Item style={{display: 'inline-block'}} label="Ort">
                        {getFieldDecorator('signatureCity', {
                            validateFirst: true,
                            initialValue: (props.serviceReceipt.items.edges && props.serviceReceipt.items.edges[0]?.node.customerObject?.city) ? props.serviceReceipt.items.edges[0]?.node.customerObject?.city : null,
                            rules: [{required: true, message: 'Bitte gib einen Ort an', whitespace: true}],
                        })(<Input/>)}
                    </Form.Item>
                    <Form.Item style={{display: 'inline-block', marginLeft: 16}} label={"Datum"}>
                        <div>{dayjs().format('DD.MM.YYYY')}</div>
                    </Form.Item>
                    <Form.Item label={"Unterschrift Auftraggeber"}>
                        {getFieldDecorator('signatureImageFile', {
                            rules: [{required: true, message: 'Bitte unterschreibe in der roten Fläche'}],
                        })(<SignatureInput/>)}
                    </Form.Item>
                    <Form.Item label="Name des Unterzeichnenden">
                        {getFieldDecorator('signatoryName', {
                            validateFirst: true,
                            initialValue: (props.serviceReceipt.customer.firstName && props.serviceReceipt.customer.lastName) ? (props.serviceReceipt.customer.firstName + ' ' + props.serviceReceipt.customer.lastName) : ((props.serviceReceipt.customerContactPerson?.firstName && props.serviceReceipt.customerContactPerson?.lastName) ? (props.serviceReceipt.customerContactPerson?.firstName + ' ' + props.serviceReceipt.customerContactPerson?.lastName) : null),
                        })(<Input allowClear={true} style={{maxWidth: 250}}/>)}
                    </Form.Item>
                    <Button style={{marginTop: 16}} loading={submitting} type={"primary"}
                            onClick={handleSubmit}>Unterschreiben</Button>
                </>}
            </Form>
        </div>
    );
}

export default Form.create<ServiceReceiptSignFormProps>({
    name: 'signServiceReceipt'
})(ServiceReceiptSignForm);