import {
    commitMutation
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'
import {SignServiceReceiptInput, SignServiceReceiptMutation} from "./__generated__/SignServiceReceiptMutation.graphql";

const mutation = graphql`mutation SignServiceReceiptMutation($input: SignServiceReceiptInput!) {
    signServiceReceipt(input: $input) {
      serviceReceipt {
        id
      }
    }
  }
`;

const commit = (signServiceReceiptInput: SignServiceReceiptInput, callback) => {
    const variables = {
        input: signServiceReceiptInput
    };

    commitMutation<SignServiceReceiptMutation>(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                const id = response.signServiceReceipt?.serviceReceipt?.id;
                callback(id)
            },
            onError: err => console.error(err),
        },
    )
}

export default commit;