import {
    commitMutation
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro';
import environment from '../Environment'
import {
    CompleteServiceReceiptItemInput,
    CompleteServiceReceiptItemMutation
} from "./__generated__/CompleteServiceReceiptItemMutation.graphql";

const mutation = graphql`mutation CompleteServiceReceiptItemMutation($input: CompleteServiceReceiptItemInput!) {
    completeServiceReceiptItem(input: $input) {
      serviceReceiptItem {
        id
      }
    }
  }
`;

const commit = (completeServiceReceiptItemInput: CompleteServiceReceiptItemInput, callback) => {
    const variables = {
        input: completeServiceReceiptItemInput
    };

    commitMutation<CompleteServiceReceiptItemMutation>(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                const id = response.completeServiceReceiptItem?.serviceReceiptItem?.id;
                callback(id);
            },
            onError: err => console.error(err),
        },
    )
}

export default commit;